import { Button, Input } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function ForgetPassword() {
  return (
    <div className='login-box'>
       <ul className='row'>
        <li>
            <form>
                <div className='logo'>
                    <img src='/images/ace-sync.png'   />
                    <span>Ace-sync</span>
                </div>
                
          <h2>Let me Help !</h2>
          <p>Enter Your Credentials to Access your dashboard, exciting things are waiting for you!</p>
          
          <br/>
          <br/> 
          <lable>Enter Email Id</lable>
          <Input placeholder="Enter Your Email Id" />
          <br/> 
           <br/> 
          <Button type="primary" block={true} style={{backgroundColor:'#3e4435'}}>Send Reset Password Link</Button>
          
          <br/> 
          <br/>
          
          
          <p style={{textAlign:'center'}}>Remember Your Password? <strong style={{color:'#3e4435'}}> <Link to="/login">Login Here</Link></strong></p>

          </form>
        </li>
        <li>
          
        </li>
       </ul>
    </div>
  )
}

export default ForgetPassword
