import React, { useState } from 'react';
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  TreeSelect,
  notification
} from 'antd';
import axios from 'axios';
import { Loading3QuartersOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};
const openNotificationWithIcon = (type,message) => {
    notification[type]({ 
      description:message,
    });
  };

 
// Function to get CSRF token from cookies
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};
 
const ProductAddForm = ()=>{
  const [isLoading,setIsLoading]=useState(false);
  const saveProperties = async (data) => {
    try {
      setIsLoading(true);
        // Get the CSRF token from cookies
        const csrftoken = getCookie('csrftoken');
        
        // Set up headers with CSRF token
        const headers = {
            'X-CSRFToken': csrftoken,
            'Content-Type': 'application/json' // Optional, depending on your API
        };
        const formData = new URLSearchParams();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        // Send POST request with headers
        const response = await axios.post('http://64.227.174.187/api/products/add/', formData.toString() );

        // Check response status and show notification accordingly
        if (response.status === 200) {
          setIsLoading(false);
            openNotificationWithIcon('success', response.data.message);
        } else {
          setIsLoading(false);
            openNotificationWithIcon('info', response.data.message);
        }
    } catch (error) {
      setIsLoading(false);
        console.error('Error:', error);
        openNotificationWithIcon('error', error.message); 
    }
};
return (
  
  <>
  <h1>Add Product Lot</h1>
  <Form
    {...formItemLayout}
    variant="filled"
    layout="vertical"
    method='POST'
    style={{
      
    }}
    onFinish={(e)=>saveProperties(e)}
    
  > 
    <Form.Item
      label="Name of Product"
      name="product_name"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input style={{
          width: '100%',
        }} name="product_name" placeholder='Product Name' />
    </Form.Item>

    <Form.Item
      label="Product Category"
      name="category"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input
        style={{
          width: '100%',
        }}
        name='category'
        placeholder='product_category'
      />
    </Form.Item>
    <Form.Item
      label="Product Subcategory"
      name="subcategory"
      rules={[
        {
            type:'text',
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input type='text' style={{
          width: '100%',
        }} placeholder='Product Subcategory' name='subcategory' />
    </Form.Item>
    <Form.Item
      label="Number of Products in Lot"
      name="number_of_prods"
      rules={[
        {
            type:'number',
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <InputNumber type='number' style={{
          width: '100%',
        }} placeholder='No. of Products' name='number_of_prods' />
    </Form.Item>
    <Form.Item
      label="Batch No."
      name="batch_num"
      rules={[
        {
            type:'text',
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input type='text' style={{
          width: '100%',
        }} placeholder='Batch Number' name='batch_num' />
    </Form.Item>

     

    <Form.Item
      wrapperCol={{
        offset: 6,
        span: 16,
      }}
    >
      {isLoading?'Loading Please Wait..':
      (<Button type="primary" isLoading={isLoading}   htmlType="submit">
      Submit
      </Button>)}
    </Form.Item>
  </Form>
  </>
)};
export default ProductAddForm;