import { Button, Modal } from 'antd';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ProductTd from './ProductTd';


function ProductList() {
  const [products,setProducts]=useState([]);
 
  const [filteredProducts,setFilteredProducts]=useState([]);
  useEffect(()=>{
   loadProducts();
  },[])

  const loadProducts = async()=>{
   try{
    const response = await axios.get('http://64.227.174.187/api/products/list/');
    setProducts(response.data)
    var filteredProducts=[];
    var lots; 
    var set1;
    set1= new Set(response.data.map((resp)=>resp.lot_id))
    console.log(set1)
    set1.forEach((lotId,index)=>{
      var lotDetails;
      lotDetails=[];
      lotDetails['lot_id']=lotId;
      lotDetails['products']=[];
      lotDetails['productName']=response.data.filter((p)=>p.lot_id==lotId)[0];
      response.data.forEach((pr)=>{
        
      if(pr.lot_id==lotId){
        lotDetails['products'].push(pr.product_qr_code_link);
      }
      })
      
      filteredProducts.push(lotDetails)
    })
    setFilteredProducts(filteredProducts);
   }catch(e){
    alert("Recived a catch Error"+e.message);
   }
  }
  return (
    <>
    
    <div className='product_list'>
      <h1>Product List</h1>
      <ul style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderTop:'1px solid gray'}}>
        <li>#</li>
        <li>Product Specification</li>
        <li>Quantity</li>
       
        <li>Lot Id</li>
        <li>Batch No.</li>
        <li>View Qrs</li>
      </ul>
      
      {filteredProducts.map((product,key)=>
      <ProductTd product={product} key={key} keyId={key}/>
      )}
    </div>
    </>
  )
}

export default ProductList
