import React from 'react'
import AppHeader from '../../Components/AppHeader'

function Clubs() {
  return (
    <>
    <div className="app">
        
        <AppHeader/>
        <section className='body'>
        Clubs
        </section>
        <footer className='mobile-footer'>
          Footer
        </footer>
       </div>
    </>
  )
}

export default Clubs
