import React, { useState } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  LinkOutlined,
  MenuOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import AppHeader from '../../Components/AppHeader';
import FactoriesList from './FactoriesList';
import AddFactories from './AddFactories';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Factory List', '1', <PieChartOutlined />),
  getItem('Add New Factory', '2', <DesktopOutlined />),
  getItem('Factory Details', '3', <FileOutlined />),
  getItem('Production Logs', '4', <LinkOutlined />),
  getItem('Quality Control Reports', '5', <FileOutlined />),

];
const Factories = () => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [currentItem, setCurrentItem]= useState(1);
  const handleMenuClick = (e) => {
     
    setCurrentItem(e.key)
};
  return (
    <>
    <div className='app'>
    <AppHeader/>
    <Layout
      style={{
        marginTop:'65px',
        minHeight: '100vh',
      }}
    >
      <Sider collapsedWidth={0} collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" onClick={handleMenuClick}>
                {items.map(item => (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ))}
            </Menu>
      </Sider>
      <Layout>
        
        
        <Content
          style={{
            margin: '0 10px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item><span onClick={()=>setCollapsed(!collapsed)}><MenuOutlined/></span>Factories</Breadcrumb.Item>
            <Breadcrumb.Item></Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 14,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          > 
          {currentItem==1 &&  <FactoriesList/>} {currentItem==2 &&  <AddFactories/>}
            
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Advance Supply Management System ©{new Date().getFullYear()} Developed by <a href="https://acetians.com" target='blank'>Acetians Technologies</a>
        </Footer>
      </Layout>
    </Layout>
    </div>
    </>
  );
};
export default Factories;