import React from 'react'

function DataCard() {
  return (
    <div className='data-card'>
      <h4>Data Cards</h4>
    </div>
  )
}

export default DataCard
