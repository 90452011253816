import { CiOutlined, DollarCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import React from 'react'

function InventoryCards() {
  return (
    <div className='inventory-cards'>
       <ul>
        <li>
            <VerticalAlignBottomOutlined style={{fontSize:'50%'}}/>
        </li>
        <li>
            <p>Inventory Value</p>
            <p>₹ 230000.00</p>
            <p>Changes : -4000</p>
        </li>
       </ul>
    </div>
  )
}

export default InventoryCards 