import React from 'react'
import { Link, useLocation } from 'react-router-dom'


function NavItem(props) {
    const loc= useLocation();
  return (
    <div  className={((loc.pathname==="/"+props.name.toLowerCase()) || (props.name=='Dashboard' && loc.pathname=="/"))?'nav-item nav-active':'nav-item'} >
      <Link to={props.link}>
        {props.name} 
      </Link>
    </div>
  )
}

export default NavItem
