import { Avatar, Drawer, Dropdown, Menu } from 'antd'
import { BellOutlined, NotificationOutlined, PoweroffOutlined, ShoppingCartOutlined, UserOutlined,ProfileOutlined,SettingOutlined, MenuOutlined } from '@ant-design/icons'
import React, { useContext, useState } from 'react'
import NavItem from './NavItem'
import { useLocation } from 'react-router'
import MenuItem from 'antd/es/menu/MenuItem'
import DropdownButton from 'antd/es/dropdown/dropdown-button'
import { AuthContext } from '../auth/AuthContext'




function AppHeader(prop) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const { logoutUser } = useContext(AuthContext);
  const menu = (
    <Menu style={{width:'200px'}}>
      <Menu.Item key="profile">
        <ProfileOutlined className="icon" />
        Profile
      </Menu.Item>
      <Menu.Item key="settings">
        <SettingOutlined className="icon" />
        Settings
      </Menu.Item>
      <Menu.Item key="signout" onClick={()=>logoutUser()}>
        <PoweroffOutlined className="icon" />
        Sign out
      </Menu.Item>
    </Menu>
  );
    
  return (
    <>
    <Drawer title={<img src='https://acetians.com/assets/img/logo1.png'
          style={{height:'50px'}}
          />} onClose={onClose} open={open}>
    <ul>
            <li><NavItem link="/"  name="Dashboard" isAcive={true} /></li>
            <li><NavItem link="/factories" name="Factories" isAcive={false} /></li>
            <li><NavItem link="/products" name="Products" isAcive={false} /></li>
            <li><NavItem link="/analytics" name="Analytics" isAcive={false} /></li>
            <li><NavItem link="/customers" name="Customers" isAcive={false} /></li>
             
          </ul>
      </Drawer>
    <header>
      <ul>
        <li>
        <span id="menu-toggle" onClick={()=>showDrawer()} style={{color:'white',paddingInlineEnd:'20px'}}><MenuOutlined style={{color:'white'}}/></span>
          <img src='https://acetians.com/assets/img/logo1.png'
          style={{height:'50px'}}
          />
        </li>
        <li>
          <ul>
            <li><NavItem link="/"  name="Dashboard" isAcive={true} /></li>
            <li><NavItem link="/factories" name="Factories" isAcive={false} /></li>
            <li><NavItem link="/products" name="Products" isAcive={false} /></li>
            <li><NavItem link="/analytics" name="Analytics" isAcive={false} /></li>
            <li><NavItem link="/customers" name="Customers" isAcive={false} /></li>
             
          </ul>

        </li>
        <li>
          <ul>
            <li>
              <BellOutlined style={{fontSize:'25px',marginInline:'10px'}} />
            </li>
             
            <li>
              <Dropdown overlay={menu} trigger={['click']}>
                <a href='#'>
                 <Avatar size={40} icon={<UserOutlined/>} /> 
                </a>
                
              </Dropdown>
              
            </li>
            
          </ul>

        </li>
      </ul>
    </header>
    </>
  )
}

 

export default AppHeader
