import React from 'react'

function GraphicsCard() {
  return (
    <div className='graphics-card'>
      Graphs Card
    </div>
  )
}

export default GraphicsCard
