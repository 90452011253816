import React, { useState } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  LinkOutlined,
  MenuOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import AppHeader from '../../Components/AppHeader';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Sales Analytics', '1', <PieChartOutlined />),
  getItem('Production Analytics', '2', <DesktopOutlined />),
  getItem('Quality Control Anlaytics', '3', <FileOutlined />),
  getItem('Customer Feed Analytics', '4', <LinkOutlined />),
  getItem('Lot Wise Details', '5', <FileOutlined />),
  getItem('Location Wise Details', '5', <FileOutlined />),

];
const Analytics = () => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <>
    <div className='app'>
    <AppHeader/>
    <Layout
      style={{
        marginTop:'65px',
        minHeight: '100vh',
      }}
    >
      <Sider collapsedWidth={0} collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout>
        
        
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item> <span onClick={()=>setCollapsed(!collapsed)}><MenuOutlined/></span>Analytics</Breadcrumb.Item>
            <Breadcrumb.Item></Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            Sample Dashboard
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Advance Supply Management System ©{new Date().getFullYear()} Developed by <a href="https://acetians.com" target='blank'>Acetians Technologies</a>
        </Footer>
      </Layout>
    </Layout>
    </div>
    </>
  );
};
export default Analytics;