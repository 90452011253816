import { Button, Modal } from 'antd';
import React, { useState } from 'react'


function ProductTd(prop) {
  const [isModalOpen,setIsModalOpen]=useState(false);
  return (
    <>
      <Modal width={1420} open={isModalOpen} destroyOnClose={true} onCancel={()=>{setIsModalOpen(true)}} onOk={()=>{setIsModalOpen(false)}}>
       <h1>Viewing QR</h1>
       <p>Lot QR</p> 
       <img src={prop.product.productName.lot_qr_code_link} style={{height:'200px',width:'200px'}}/>
       <p>Products Qr</p>
      <ul>
      {prop.product.products.map((pr)=><img src={pr} style={{height:'200px',width:'200px'}}/>)}

      </ul>

    </Modal>
    <ul style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderTop:'1px solid gray'}}>
          
        <li>{prop.keyId+1}</li>
        <li>Product-{prop.product.productName.product_name}
            <p>Cat. : {prop.product.productName.product_cat}</p>
            <p>Subcat. : {prop.product.productName.product_subcategory}</p>
        </li>
        <li>{prop.product.products.length} Products </li> 
        
        <li>{prop.product.lot_id} </li>
        <li>{prop.product.productName.batch_no}</li>
        <li> <Button onClick={()=>setIsModalOpen(true)}>View Qrs</Button> </li>
        </ul>
    </>
  )
}

export default ProductTd
