import axios from 'axios';
import React, { useEffect, useState } from 'react'
import FactoryCard from './FactoryCard';

function FactoriesList() {
  const [factories,setFactories]=useState([]);
  const [reload,setReload]=useState(false);
  useEffect(()=>{
   loadFactories()
  },[reload])
  const loadFactories = async()=>{
   const response= await axios.get('http://64.227.174.187/api/factories/list/');

   
   
   if(response.status==200){
    // var list= JSON.parse(response.data);
     setFactories(response.data);
   }else{
    console.log('Failed to load : '+response.reasonPhrase);
   }
   
  }
  return (
    <div className='factories_list'>
        <h1>Factories</h1>
      {factories.length==0 && (<div className='' style={{minWidth:'100vh',height:'60vh',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}><img src='https://cdn-icons-png.flaticon.com/128/7486/7486820.png' /><p>No Factories Found Try Creating one using option in side bar</p></div>)}   
      { factories.length>0 && (<ul>
        {factories.map((factory)=>(<li key={factory.id}><FactoryCard factory={factory} onRefresh={()=>setReload(!reload)} /></li>))}
      </ul>)}
    </div>
  )
}

export default FactoriesList
