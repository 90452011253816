import React, { useState } from 'react';
import {
  DesktopOutlined,
  FileOutlined,
  LinkOutlined,
  MenuOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import AppHeader from '../../Components/AppHeader';
import InventoryCards from './InventoryCards';
import DataCard from './components/DataCard';
import GraphicsCard from './components/GraphicsCard';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Dashboard', '1', <PieChartOutlined />),
  getItem('Sales Performance', '2', <DesktopOutlined />),
  getItem('Production Metrics', '3', <FileOutlined />),
  getItem('Supply Chain Status', '4', <LinkOutlined />),
  getItem('Financial Reports', '5', <FileOutlined />),

];
const Home = () => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <>
    <div className='app'>
    <AppHeader  menu={setCollapsed}/>
    <Layout
      style={{
        marginTop:'65px',
        minHeight: '100vh',
      }}
    >
      <Sider  collapsedWidth={0}  collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
      </Sider>
      <Layout>
        
        
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item><span onClick={()=>setCollapsed(!collapsed)}><MenuOutlined/></span>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item></Breadcrumb.Item>
          </Breadcrumb>
          <div
          className='dash'
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <ul id="inventory-cards-ul">
              <li> <InventoryCards/> </li>
              <li> <InventoryCards/> </li>
              <li> <InventoryCards/> </li>
              <li> <InventoryCards/> </li>
            </ul>
            <ul>
              <li>
                <DataCard/>
              </li>
              <li>
                <DataCard/>
              </li>
              <li>
                <ul>
                  <li>
                  <DataCard/>
                  </li>
                  <li>
                <DataCard/>
              </li>
                </ul>
              </li>
            </ul>
            <ul id="grahics-ul">
              <li>
                <GraphicsCard />
              </li>
              <li>
                <GraphicsCard/>
              </li>
            </ul>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Advance Supply Management System ©{new Date().getFullYear()} Developed by <a href="https://acetians.com" target='blank'>Acetians Technologies</a>
        </Footer>
      </Layout>
    </Layout>
    </div>
    </>
  );
};
export default Home;