import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './auth/Login';
import Register from './auth/Register';
import ForgetPassword from './auth/ForgetPassword';
import Home from './user/home/Home';
import axios from 'axios';
import Feeds from './user/products/Products';
import Courses from './user/factories/Factories';
import Challenge from './user/challenges/Challenge';
import Rewards from './user/analytics/Analytics';
import Certifications from './user/customers/Customers';
import Support from './user/support/Support';
import Clubs from './user/clubs/Clubs';
import {  AuthContext, AuthProvider, useAuth } from './auth/AuthContext'; 
import PrivateRoute from './auth/PrivateRoute';
import GuestRoute from './auth/GuestRoute'; 
import Factories from './user/factories/Factories';
import Analytics from './user/analytics/Analytics';
import Customers from './user/customers/Customers';
import Products from './user/products/Products';



function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const isAuthenticated = localStorage.getItem('access_token');
  
  const fetchUser = async () => {
    try {
       
      const response = await axios.get('http://64.227.174.187/api/v1/user', {
        headers: {
          'Authorization': `Bearer ${isAuthenticated}`
        }
      });
      // Assuming user data is retrieved successfully
      console.log(response.data);
      
       
    } catch (error) {
      // Handle error or unauthorized access
      console.error('Error fetching user data:', error);
      // Clear access token from localStorage if request fails or unauthorized
      
       localStorage.removeItem('access_token');
    }
  };
  
  useEffect(() => {
    if (isAuthenticated) {
      
      

      fetchUser();
    }
  }, [isAuthenticated]);
   
  

  return ( 
    <AuthProvider>
      <BrowserRouter>
        <Routes> 
        <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<Home/>}/>
          </Route>
          <Route   path='/factories' element={<PrivateRoute/>}>
            <Route exact path='/factories' element={<Factories/>}/>
          </Route>
          <Route   path='/products' element={<PrivateRoute/>}>
            <Route exact path='/products' element={<Products/>}/>
          </Route>
          <Route exact path='/analytics' element={<PrivateRoute/>}>
            <Route   path='/analytics' element={<Analytics/>}/>
          </Route>
          <Route  path='/customers' element={<PrivateRoute/>}>
            <Route  path='/customers' element={<Customers/>}/>
          </Route>
           
          {/* Login  */}
          
          <Route exact path='/login' element={<GuestRoute/>}>
            <Route exact path='/login' element={<Login/>}/>
          </Route>
          <Route
            path="/register"
            element={!isAuthenticated ? <Register /> : <Navigate to="/" />}
          />
          <Route
            path="/forget-password"
            element={!isAuthenticated ? <ForgetPassword /> : <Navigate to="/" />}
          />
        </Routes>
      </BrowserRouter> 
      </AuthProvider>
  );
}

export default App;
