import React, { useContext, useState } from 'react';
import { Button, Input, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
// import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import { useAuth } from './AuthContext';




function Login() { 
  const {loginUser} = useAuth();
  const [messages,setMessages]=useState('');
  const [usernameError,setUsernameError]=useState('');
  const [email,setEmail]=useState();
  const [password,setPassword]=useState();
  
  const navigate  = useNavigate(); // Initialize useHistory hook
  const openNotificationWithIcon = (type,message) => {
    notification[type]({ 
      description:message,
    });
  };
  const plainLogin = async(creds) => {
    await loginUser(email,password);
    
  };
  const signinWithGoogle = (creds) => {
    axios.post('http://64.227.174.187/api/v1/signin-with-google', creds)
      .then(response => {
        localStorage.setItem('access_token', response.data.access_token);
        navigate('/'); // Navigate to the home page
        console.log(response.data); // Handle successful response
      })
      .catch(error => {
        console.error('Error:', error); // Handle error
      });
  };

  return (
    <div className='login-box'>
      <ul className='row'>
        <li>
          <form>
            <div className='logo'>
              <img src='/images/ace-sync.png' alt="Ace-sync Logo" />
              <span>Ace RMS</span>
            </div>

            <h2>Welcome Back</h2>
            <p>Please Login to manage your Supply chain management System Seamlessly.</p>

            <br />
            <span style={{color:'red'}}>{messages}</span>
            <br />
            <label>Enter Email Id</label>
            <Input placeholder="Enter Your Email Id" onChange={(e)=>setEmail(e.target.value)} />
            <span style={{'color':'yellow',fontSize:'12px'}}>{usernameError}</span>
            <br />
            <br />
            <label>Enter Password</label>
            <Input placeholder="Enter Password"  onChange={(e)=>setPassword(e.target.value)} />
            <p style={{ textAlign: 'right' }}><Link to='/forget-password'>Forget Password ? </Link> </p>
            <br />
            <Button type="primary" block={true} style={{ backgroundColor: '#3e4435' }} onClick={plainLogin}>Login</Button>

            <br />
            <br />
          </form>

           
        </li>
        <li>

        </li>
      </ul>
    </div>
  );
}

export default Login;
