import { DeleteOutlined, EditOutlined, MailOutlined, OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Avatar, Card, Modal, notification } from 'antd'
import Meta from 'antd/es/card/Meta'
import axios from 'axios';
import React, { useEffect, useState } from 'react'


function FactoryCard(factory) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [name,setName]=useState(factory.factory.name);
  const [address,setAddress]=useState(factory.factory.address);
  const [estdate,setEstdate]=useState(factory.factory.established_date);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModal2Open(true);
  };
  
  const editFactoryPost = async (v) => {
    const formData2 = new URLSearchParams();
    formData2.append('email', v);
    formData2.append('name', name); // Using the current value of 'name' state
    formData2.append('date', estdate); // Using the current value of 'estdate' state
    formData2.append('address', address); // Using the current value of 'address' state
  
    const response = await axios.post('http://64.227.174.187/api/factories/edit/', formData2);
    
    if (response.status === 200) {
      openNotificationWithIcon('success', response.message);
      factory.onRefresh();
    } else {
      openNotificationWithIcon('error', `${response.statusText}`);
    }
  }
  
useEffect(()=>{
  setName(factory.factory.name);
setAddress(factory.factory.address);
setEstdate(factory.factory.established_date);
},[])
  const openNotificationWithIcon = (type,message) => {
    notification[type]({ 
      description:message,
    });
  };
  const handleOk2=async(v)=>{
  editFactoryPost(v);
  setIsModal2Open(false);
  }
  const handleOk =  async(v) =>{
    const formData = new URLSearchParams();
   //for (const key in data) {
       formData.append('email', v);
  // }
    const response = await axios.post('http://64.227.174.187/api/factories/remove/',formData)
    
    if(response.status == 200){
    openNotificationWithIcon('success','Removed Succesfully');
    factory.onRefresh()
    }else{
    openNotificationWithIcon('error',`${response.statusText}`);  
    
    }
    setIsModalOpen(false);
    
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModal2Open(false);
  };
  return (
    <>
    <Modal title="Delete Confirmation" open={isModalOpen} onOk={()=>handleOk(factory.factory.email)} onCancel={()=>handleCancel()}>
        <p>Delete Confirmation</p>
        <p>Are you sure you want to remove(soft delete) this factory from list?</p>
        
      </Modal>

    {/* Edit Modal    */}
    <Modal title="Edit Factory" open={isModal2Open} onOk={()=>handleOk2(factory.factory.email)} onCancel={()=>handleCancel2()}>
        <div className='form-group'>
        <label>Factory Name</label>
          <input type='text' name='name' onChange={(e)=>setName(e.target.value)} value={name} />
        </div>
        <div className='form-group'>
          <label>Establish Date</label>
          <input type='date' name='date' onChange={(e)=>setEstdate(e.target.value)} value={estdate} />
        </div>
        <div className='form-group'>
          <label>Address</label>
          <input type='text' name='date' onChange={(e)=>setAddress(e.target.value)} value={address} />
        </div>
        
      </Modal>
    <Card
    style={{
      
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
    }}
    
    cover={
      <img
        alt="example"
        src="https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"
        style={{height:'100px'}}
      />
      
    }
     
  >
    
     
    <h3>{factory.factory.name}</h3>
    <h5>{factory.factory.address}</h5>
    <p><MailOutlined/> {factory.factory.email}</p>
    <p><UnorderedListOutlined/> {factory.factory.established_date}</p>
    <ul style={{display:'flex',justifyContent:'end'}}>
      <li style={{paddingInline:'3px'}}><a href="#" style={{color:"black"}} onClick={()=>showModal2()}> <EditOutlined/> </a></li>
      <li style={{paddingInline:'3px'}}><a href="#" onClick={()=>showModal()} style={{color:"black"}}><DeleteOutlined/></a></li>
    </ul>
  </Card>
  </>
  )
}

export default FactoryCard
