import React from 'react';
import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Mentions,
  Select,
  TreeSelect,
  notification
} from 'antd';
import axios from 'axios'; 
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
}; 
const openNotificationWithIcon = (type,message) => {
    notification[type]({ 
      description:message,
    });
  };
  const saveProperties = async (data) => {
    try {
        // Get the CSRF token from cookies
        const csrftoken = getCookie('csrftoken');
        
        // Set up headers with CSRF token
        const headers = {
            'X-CSRFToken': csrftoken,
            'Content-Type': 'application/json' // Optional, depending on your API
        };
        const formData = new URLSearchParams();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        // Send POST request with headers
        const response = await axios.post('http://64.227.174.187/api/factories/save/', formData.toString() );

        // Check response status and show notification accordingly
        if (response.status === 200) {
            
            openNotificationWithIcon('success', response.data.message); 
          
        } else {
            openNotificationWithIcon('info', response.data.message);
        }
    } catch (error) {
        console.error('Error:', error);
        openNotificationWithIcon('error', error.message); 
    }
};
 
// Function to get CSRF token from cookies
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

const AddFactories = () => (
  <>
  <h1>Add Factories</h1>
  <Form
    {...formItemLayout}
    variant="filled"
    layout="vertical"
    method='POST'
    style={{
      
    }}
    onFinish={(e)=>saveProperties(e)}
    
  > 
    <Form.Item
      label="Name of Factory"
      name="name"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input style={{
          width: '100%',
        }} name="name" />
    </Form.Item>

    <Form.Item
      label="Phone number"
      name="mobile"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <InputNumber
        style={{
          width: '100%',
        }}
        name='mobile'
      />
    </Form.Item>
    <Form.Item
      label="Email Id"
      name="email"
      rules={[
        {
            type:'email',
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <Input type='email' style={{
          width: '100%',
        }} name='email' />
    </Form.Item>

    <Form.Item
      label="Factory Formation Date"
      name="establish_date"
      rules={[
        {
          required: true,
          message: 'Please input!',
        },
      ]}
    >
      <DatePicker style={{
          width: '100%',
        }} />
    </Form.Item>

     

    <Form.Item
      wrapperCol={{
        offset: 6,
        span: 16,
      }}
    >
      <Button type="primary"   htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
  </>
);
export default AddFactories;