// src/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { jwtDecode } from 'jwt-decode';




const AuthContext = createContext();
const openNotificationWithIcon = (type,message) => {
    notification[type]({ 
      description:message,
    });
  };
const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem('tokens') ? JSON.parse(localStorage.getItem('tokens')) : null);
    const [user, setUser] = useState(() => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);

    const loginUser = async (username, password) => {
       try{
        const response = await axios.post('http://64.227.174.187/api/login', {
            username,
            password
        });
        if (response.data.access) {
            setAuthTokens(response.data);
            setUser(username);
            localStorage.setItem('tokens', JSON.stringify(response.data));
            localStorage.setItem('user', JSON.stringify(username));
        }else{
            openNotificationWithIcon('error',response.data.message)    
        }
       }catch(e){
    openNotificationWithIcon('error',e.message)
       }
    };

    const loggedInUserCheck = async (token) => {
        try{
         const response = await axios.post('http://64.227.174.187/api/user', {},{
            headers:{
                'Authorization':`Bearer ${token}`
            }
         });
         if (response.data.access) {
            //  setAuthTokens(response.data);
            //  setUser(username);
            //  localStorage.setItem('tokens', JSON.stringify(response.data));
            //  localStorage.setItem('user', JSON.stringify(username));
         }else{
            //  openNotificationWithIcon('error',response.data.message)    
         }
        }catch(e){
     //openNotificationWithIcon('error',e.message)
        }
     };

    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('tokens');
        localStorage.removeItem('user');
    };

    useEffect(()=>{
    if(authTokens){
        try{
            const interval= setInterval(()=>{
                const {exp} = jwtDecode(authTokens.access);
                const expirationTime= exp*1000;
                console.log(expirationTime);
                const currentTime = new Date().getTime();
                const timeUntilExpiration = expirationTime - currentTime;
                const refreshTime = Math.max(0, timeUntilExpiration - (5 * 60 * 1000)); // Refresh 5 minutes before expiration
    
                console.log(timeUntilExpiration);
                if(timeUntilExpiration<1){
                    //refresh
                  //  logoutUser();
                }
                // const timeout = setTimeout(refreshToken, refreshTime);
                 return () => clearTimeout(interval);
            },10000)
        }catch(e){
            //logoutUser();
        }
    }
    },[authTokens])

    return (
        <AuthContext.Provider value={{ user, authTokens, loginUser, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuth };